import "./editClassLocationSchedule.scss";
import { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Button from "../../formUiElements/Button";
import { useClassContext } from "../../../contexts/ClassContext";
import { BeatLoader } from "react-spinners";
import {
  UPDATE_CLASS_SCHEDULE,
  UPDATE_CLASS_SCHEDULE_SERIES,
} from "../../../gqloperations/mutations";
import {
  GET_CLASS_SCHEDULE_BY_ID,
  GET_STUDIO_BY_LOCATION_ID,
  GET_LOCTAION_BY_ID,
} from "../../../gqloperations/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import Select from "../../formUiElements/select/Select";
import Input from "../../formUiElements/input/Input";
import { isThisSeries } from "../../../constants/commonConstant";
import { editClassScheduleSchema } from "../../../validations/ClassValidation";
import SelectWithoutFormik from "../../formUiElements/select/SelectWithoutFormik";
import { useMemberContext } from "../../../contexts/MemberContext";
import { AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import ToggleFormik from "../../formUiElements/ToggleFormik";
import {
  getUTCdate,
  readUTCtimeToTargetTimeZone,
} from "../../../utils/getUTCdate";
import { useHandleError } from "../../../hooks/useHandleError";

const EditClassLocationSchedule = (props) => {
  const { permissions } = useAuthContext();
  const [listStudioByLocationId] = useLazyQuery(GET_STUDIO_BY_LOCATION_ID);
  const navigate = useNavigate();

  const {
    downloadAndDecode,
    imgUrl,
    setImgUrl,
    encodeAndUploadImageToAws: uploadImageToAws,
  } = useMemberContext();

  const [updateClassSchedule, { error: updateclasserror }] = useMutation(
    UPDATE_CLASS_SCHEDULE
  );

  const [updateSeriesClass, { error: updateclassSerieserror }] = useMutation(
    UPDATE_CLASS_SCHEDULE_SERIES
  );
  const { classScheduledata, id } = props;
  const fileInputRef = useRef();
  const [file, setFile] = useState(false);
  const [studioData, setStudioData] = useState();
  const { allInstructor } = useClassContext();
  const [isActive, setIsActive] = useState(true);
  const [selectedInstructor, setSelectedInstructor] = useState();

  const handleError = useHandleError();

  useEffect(() => {
    if (classScheduledata?.getClassSchedule?.instructorDetails?.givenName) {
      setSelectedInstructor(
        classScheduledata?.getClassSchedule?.instructorDetails?.givenName +
          " " +
          classScheduledata?.getClassSchedule?.instructorDetails?.surname
      );
    }
  }, [classScheduledata]);

  const [selectType, setSelectType] = useState(
    classScheduledata?.getClassSchedule?.isThisSeries
      ? "Whole Series"
      : "Single Class"
  );
  const [errorMessage, setErrorMessage] = useState("");
  const {
    data,
    loading,
    error: getClassLocationError,
  } = useQuery(GET_LOCTAION_BY_ID, {
    variables: {
      id: classScheduledata?.getClassSchedule?.locationId ?? "",
    },
  });
  useEffect(() => {
    listStudioByLocationId({
      variables: {
        locationId: `${classScheduledata?.getClassSchedule?.locationId}`,
      },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setStudioData(data);
      },
      onError: (error) => {
        handleError("Error: Cannot get location studios");
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classScheduledata]);
  useEffect(() => {
    const loadImage = async () => {
      try {
        if (classScheduledata?.getClassSchedule?.imageUrl) {
          await downloadAndDecode(
            classScheduledata?.getClassSchedule?.imageUrl
          );
        } else {
          setImgUrl("");
        }
      } catch (error) {
        handleError(`Error: ${error}`);
        setImgUrl("");
      }
    };

    loadImage();

    // Clear image state when component unmounts or when class data changes
    return () => {
      setImgUrl("");
      setFile(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classScheduledata?.getClassSchedule?.imageUrl]);

  const handleIsActive = () => {
    setIsActive(false);
  };
  const handleCancel = async () => {
    toast.warning("Update Cancelled");
    document.getElementById("class-schedule-message-form").reset();
    setIsActive(true);
    setFile();
    if (classScheduledata?.getClassSchedule?.imageUrl) {
      await Storage.get(classScheduledata?.getClassSchedule?.imageUrl)
        .then((data) => setImgUrl(data))
        .catch((err) => handleError(`Error: ${err}`));
    } else {
      setImgUrl("");
    }
  };
  const updateClassImage = async () => {
    const extension = file.name.split(".");
    const updatedKey = `/classImages/${id}/${uuidv4()}.${extension}`;
    const uploadedFile = await uploadImageToAws(file, updatedKey);
    const imageUrl = uploadedFile?.key;
    return imageUrl;
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size > 5_242_880 || file.size === 5_242_880) {
      handleError("Image needs to be less than 5MB");
    } else {
      setFile(file);
      const clientSideImgUrl = URL.createObjectURL(file);
      setImgUrl(clientSideImgUrl);
    }
  };

  const handleImgUploadBtn = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  const handleTypeChange = (e) => {
    setSelectType(e.target.value);
    e.target.value && setErrorMessage("");
  };
  const onSubmit = async (values, OnSubmitProps, errors) => {
    setErrorMessage("");

    if (classScheduledata?.getClassSchedule?.isThisRepeated && !selectType) {
      setErrorMessage("Please select type");
      return;
    }

    const classStartDate = getUTCdate(
      values.classScheduleDateTime,
      data.getLocation.timezone
    );
    if (file) {
      const imageUrl = await updateClassImage();
      values.imageUrl = imageUrl;
    }
    const variableInputs = { ...values };
    if (!variableInputs.bookingLockoutMinutes)
      variableInputs.bookingLockoutMinutes = 0;
    if (!variableInputs.cancelLockoutMinutes)
      variableInputs.cancelLockoutMinutes = 0;
    if (!variableInputs.targetBooking) variableInputs.targetBooking = 0;
    if (!variableInputs.maxTeam) variableInputs.maxTeam = 0;
    if (!variableInputs.maxPerTeam) variableInputs.maxPerTeam = 0;
    if (selectType === "Whole Series") {
      try {
        await updateSeriesClass({
          variables: {
            ...variableInputs,
            isBookable: values.isBookable,
            isThisSeries: true,
            seriesId: classScheduledata?.getClassSchedule?.seriesId,
            classScheduleId: classScheduledata?.getClassSchedule?.id,
            classScheduleDateTime: classStartDate,
          },
          onCompleted: () => {
            toast.success("Update successfully");
            setIsActive(true);
          },
          refetchQueries: [
            {
              query: GET_CLASS_SCHEDULE_BY_ID,
              variables: {
                id: `${id}`,
              },
            },
          ],
        });
      } catch (error) {
        handleError(error.message);
        if (classScheduledata?.getClassSchedule?.imageUrl) {
          await downloadAndDecode(
            classScheduledata?.getClassSchedule?.imageUrl
          );
        } else {
          setImgUrl("");
        }
      }
    } else {
      try {
        await updateClassSchedule({
          variables: {
            input: {
              ...variableInputs,
              id: classScheduledata?.getClassSchedule?.id,
              classScheduleDateTime: classStartDate,
            },
          },
          onCompleted: () => {
            toast.success("Update successfully");
            setIsActive(true);
          },
          refetchQueries: [
            {
              query: GET_CLASS_SCHEDULE_BY_ID,
              variables: {
                id: `${id}`,
              },
            },
          ],
        });
      } catch (error) {
        handleError(error.message);
        if (classScheduledata?.getClassSchedule?.imageUrl) {
          await downloadAndDecode(
            classScheduledata?.getClassSchedule?.imageUrl
          );
        } else {
          setImgUrl("");
        }
      }
    }
    setImgUrl("");
    setFile(false);
    navigate("/dashboard/classes");
  };
  if (loading) {
    return (
      <div className="dashboard">
        <div className="dashboard-loading">
          <BeatLoader color="white" />
        </div>
      </div>
    );
  }
  if (getClassLocationError) {
    return (
      <div className="dashboard">
        <div className="dashboard-loading">
          Could not get class location, please contact IT support.
        </div>
      </div>
    );
  }
  if (updateclassSerieserror) {
    return (
      <>
        <p>{updateclassSerieserror}</p>
      </>
    );
  }
  if (updateclasserror) {
    <>
      <p>{updateclasserror}</p>
    </>;
  }
  return (
    <>
      {classScheduledata?.getClassSchedule?.classTemplateDetails && (
        <Formik
          enableReinitialize
          initialValues={{
            studioId: classScheduledata?.getClassSchedule?.studioId ?? "",
            instructorId:
              classScheduledata?.getClassSchedule?.instructorId ?? "",
            classScheduleDateTime: classScheduledata?.getClassSchedule
              ?.classScheduleDateTime
              ? `${readUTCtimeToTargetTimeZone(
                  classScheduledata?.getClassSchedule?.classScheduleDateTime,
                  data.getLocation.timezone,
                  "yyyy-MM-dd'T'HH:mm:ss"
                )}`
              : "",
            // format(new Date(originalRow?.createdAt), "dd/MM/yyyy HH:mm:ss")
            bookingLockoutMinutes:
              classScheduledata?.getClassSchedule?.bookingLockoutMinutes &&
              classScheduledata?.getClassSchedule?.bookingLockoutMinutes !== 0
                ? classScheduledata?.getClassSchedule?.bookingLockoutMinutes
                : "",
            cancelLockoutMinutes:
              classScheduledata?.getClassSchedule?.cancelLockoutMinutes &&
              classScheduledata?.getClassSchedule?.cancelLockoutMinutes !== 0
                ? classScheduledata?.getClassSchedule?.cancelLockoutMinutes
                : "",
            maxBooking: classScheduledata?.getClassSchedule?.maxBooking ?? "",
            targetBooking:
              classScheduledata?.getClassSchedule?.targetBooking &&
              classScheduledata?.getClassSchedule?.targetBooking !== 0
                ? classScheduledata?.getClassSchedule?.targetBooking
                : "",
            note: classScheduledata?.getClassSchedule?.note ?? "",
            isBookable:
              classScheduledata?.getClassSchedule?.isBookable ?? false,
            canWaitlist:
              classScheduledata?.getClassSchedule?.canWaitlist ?? false,
          }}
          validationSchema={editClassScheduleSchema}
          onSubmit={(values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
          }}
        >
          {(formik) => {
            return (
              <Form
                className="class-schedule-message-form pd-18 "
                id="class-schedule-message-form"
              >
                {isActive ? (
                  <>
                    <div className="title-bar pb-24">
                      <h3 className="fs-14 uppercase">Location & Schedule</h3>
                      {permissions.includes("CanEditMemberClassSchedule") && (
                        <span
                          className="icon-container edit"
                          onClick={handleIsActive}
                        >
                          <AiOutlineEdit />
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="title-bar">
                    <h3 className="fs-14 uppercase">Location & Schedule</h3>
                    <span className="icon-container ">
                      <div className="sub-containers">
                        <div className="sub-container save">
                          <Button
                            iconname="BiSave"
                            btntype="submit"
                            btnname="submit"
                            className="btn btn-edit save btn-lg fs-12 "
                          />
                        </div>
                        <div className="sub-container cancel">
                          <Button
                            iconname="AiOutlineCloseCircle"
                            btntype="reset"
                            btnname="reset"
                            className="btn  btn-edit cancel btn-lg fs-12"
                            click={handleCancel}
                          />
                        </div>
                      </div>
                    </span>
                  </div>
                )}

                <div className="image-inputs-container mb-24 flex-wrap">
                  <div>
                    <input
                      type="file"
                      onChange={handleImageUpload}
                      accept="image/*"
                      ref={fileInputRef}
                      className="staff-img-input pb-12"
                    />
                    <div className="img-container profile-img-container">
                      <img
                        src={imgUrl ? imgUrl : "https://placehold.co/150x150"}
                        alt="class"
                      />
                      {!isActive && (
                        <button className="btn" onClick={handleImgUploadBtn}>
                          Upload Picture
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="inputs-container-edit-class flex-wrap">
                    <div className="location-selectors flex-wrap">
                      {classScheduledata?.getClassSchedule?.isThisRepeated && (
                        <div>
                          <SelectWithoutFormik
                            label="Select Single/Series *"
                            initialValue={
                              classScheduledata?.getClassSchedule?.isThisSeries
                                ? "Whole Series"
                                : "Single Class"
                            }
                            optionvalue={isThisSeries}
                            selecttype="array"
                            onChange={handleTypeChange}
                            name="isThisSeries"
                            id="isThisSeries"
                            autoComplete="off"
                            disabled={isActive ? true : false}
                            className={
                              isActive
                                ? "select-element-value "
                                : "select-element-value blue"
                            }
                          />

                          {errorMessage && (
                            <div className="fs-12 error">{errorMessage}</div>
                          )}
                        </div>
                      )}
                      {studioData && (
                        <Select
                          label="Studio *"
                          name="studioId"
                          optionvalues={studioData?.getStudioByLocationId?.items.filter(
                            (obj) => obj.isActive
                          )}
                          selecttype="object"
                          objectvalue="id"
                          objectname="studioName"
                          autoComplete="off"
                          disabled={isActive ? true : false}
                          className={
                            isActive
                              ? "select-element-value"
                              : "select-element-value blue"
                          }
                        />
                      )}
                      {allInstructor && (
                        <div className="membership-select select-element">
                          <span className="heading-and-icon">
                            <label
                              className="location-heading fs-12"
                              htmlFor="membershipId"
                            >
                              Instructor*
                              <AiOutlineSearch />
                            </label>
                          </span>

                          <div className="location-value loc-select">
                            <input
                              id="id"
                              type="text"
                              list="instructor-list"
                              disabled={isActive ? true : false}
                              placeholder="Search a instructor"
                              className="select-element fs-12 text-white"
                              name="id"
                              value={selectedInstructor}
                              onChange={(e) => {
                                const selectedOption =
                                  allInstructor?.listClassInstructors?.items.find(
                                    (option) =>
                                      option.givenName +
                                        " " +
                                        option.surname ===
                                      e.target.value
                                  );
                                setSelectedInstructor(e.target.value);
                                if (selectedOption) {
                                  formik.setFieldValue(
                                    "instructorId",
                                    selectedOption.id
                                  );
                                }
                              }}
                              autoComplete="off"
                            />

                            <datalist id="instructor-list">
                              {allInstructor?.listClassInstructors?.items.map(
                                (option) => {
                                  return (
                                    <option
                                      key={option.id}
                                      value={`${option.givenName} ${option.surname}`}
                                      className="fs-14"
                                    ></option>
                                  );
                                }
                              )}
                            </datalist>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="location-inputs flex-wrap">
                      <Input
                        type="datetime-local"
                        label="Date *"
                        name="classScheduleDateTime"
                        id="classScheduleDateTime"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className={isActive ? "" : "blue"}
                      />

                      <Input
                        type="number"
                        label="Max Bookings *"
                        name="maxBooking"
                        id="maxBooking"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className={isActive ? "" : "blue"}
                      />
                      <Input
                        type="number"
                        label="Target"
                        name="targetBooking"
                        id="targetBooking"
                        autoComplete="off"
                        disabled={isActive ? true : false}
                        className={isActive ? "" : "blue"}
                      />
                    </div>
                    <div className="location-inputs flex-wrap">
                      <Input
                        type="number"
                        label="Booking Lockout (mins)"
                        name="bookingLockoutMinutes"
                        id="bookingLockoutMinutes"
                        disabled={isActive ? true : false}
                        autoComplete="off"
                      />
                      <Input
                        type="number"
                        label="Cancellation Window (mins)"
                        name="cancelLockoutMinutes"
                        id="cancelLockoutMinutes"
                        disabled={isActive ? true : false}
                        autoComplete="off"
                      />
                    </div>
                    <div className="location-inputs mb-24 flex-wrap">
                      <div className="bookable-row ">
                        <div className="detail">
                          <ToggleFormik
                            label="Bookable"
                            id="isBookable"
                            name="isBookable"
                            value={formik.isBookable}
                            disabled={isActive ? true : false}
                          />
                        </div>
                        <div className="detail">
                          <ToggleFormik
                            label="Waitlist"
                            id="canWaitlist"
                            name="canWaitlist"
                            value={formik.canWaitlist}
                            disabled={isActive ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="location-note">
                  <p className="fs-12">Message</p>
                  <textarea
                    id="note"
                    name="note"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.note}
                    disabled={isActive ? true : false}
                    className={isActive ? "" : "blue"}
                  ></textarea>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
export default EditClassLocationSchedule;
