import React from "react";
import Modal from "../../modal/Modal";
import StatusModal from "../../modal/StatusModal";
import { useMemberContext } from "../../../contexts/MemberContext";

function ProfileImage({ isActive, handleImageUpload, disabledCamera }) {
  const { imgUrl } = useMemberContext();
  const fileInputRef = React.useRef();
  const base64Img = React.useRef();
  const localstream = React.useRef();

  const [streamStarted, setStreamStarted] = React.useState(false);
  const [capturePhoto, setCapturePhoto] = React.useState(false);
  const handleOpenCamera = async (e) => {
    try {
      e.preventDefault();
      const constraints = {
        video: {
          width: {
            min: 1280,
            ideal: 1920,
            max: 2560,
          },
          height: {
            min: 720,
            ideal: 1080,
            max: 1440,
          },
          facingMode: "user",
        },
      };
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices
      ) {
        await navigator.mediaDevices.getUserMedia(constraints);
        setCapturePhoto(true);
        startStream(constraints);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleStream = (stream) => {
    const video = document.querySelector("video");
    const canvas = document.querySelector("canvas");
    canvas.width = 0;
    canvas.height = 0;
    video.srcObject = stream;
    localstream.current = stream;
    setStreamStarted(true);
  };
  const startStream = async (constraints) => {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    handleStream(stream);
  };
  const pauseCamera = () => {
    const video = document.querySelector("video");
    video.pause();
    setStreamStarted(false);
  };
  const startCamera = () => {
    const video = document.querySelector("video");
    video.play();
    setStreamStarted(true);
  };
  const doScreenshot = () => {
    const canvas = document.querySelector("canvas");
    const video = document.querySelector("video");
    const screenshotImage = document.querySelector("#profileImg");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0);
    const imgSrc = canvas.toDataURL("image/png");
    screenshotImage.src = imgSrc;
    base64Img.current = imgSrc;

    canvas.style.display = "none";
    handleImageUpload("", imgSrc);
    setCapturePhoto(false);
    const tracks = localstream.current.getTracks();
    for (let i = 0; i < tracks.length; i++) {
      tracks[i].stop();
    }
  };
  const closeCameraModal = () => {
    setCapturePhoto(false);
    setStreamStarted(false);

    const video = document.querySelector("video");
    const mediaStream = video.srcObject;
    const tracks = mediaStream.getTracks();
    tracks[0].stop();
    tracks.forEach((track) => track.stop());
  };
  const handleImgUploadBtn = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  return (
    <>
      <div className="">
        <input
          type="file"
          onChange={handleImageUpload}
          accept="image/*"
          ref={fileInputRef}
          name="imageUrl"
          className="staff-img-input pb-12"
        />
        <div className="profile-img-container">
          <img
            src={imgUrl ? imgUrl : "https://placehold.co/150x150"}
            id="profileImg"
            alt="member"
            className={imgUrl ? "" : "dim"}
          ></img>
          {!isActive && (
            <>
              <button
                className={!disabledCamera ? "btn-first" : "btn-middle"}
                onClick={handleImgUploadBtn}
              >
                Change Picture
              </button>{" "}
              {!disabledCamera && (
                <button className="btn-second" onClick={handleOpenCamera}>
                  Open Camera
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {capturePhoto && (
        <Modal>
          <StatusModal
            modalHeaderTxt="Capture image"
            modalParaText={
              <>
                <video width={"100%"} height={"100%"} autoPlay></video>
                <canvas width={500} height={500} class=""></canvas>
                <img class="screenshot-image" alt=""></img>
                {/* <button */}
                {streamStarted && (
                  <button className="btn-primary" onClick={() => pauseCamera()}>
                    capture
                  </button>
                )}
                {!streamStarted && (
                  <button className="btn-primary" onClick={() => startCamera()}>
                    try again
                  </button>
                )}
              </>
            }
            handleStatusClick={() => doScreenshot()}
            modalClose={() => {
              closeCameraModal();
            }}
          ></StatusModal>
        </Modal>
      )}
    </>
  );
}

export default ProfileImage;
