import * as yup from "yup";

import { differenceInYears } from "date-fns";
import { isValidPhoneNumber } from "libphonenumber-js";
import { number } from "card-validator";
export const staffSchema = yup.object().shape({
  givenName: yup.string().required("Given Name is required"),
  surname: yup.string().required("Surname is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  secondaryEmail: yup.string().email("Invalid email address"),
  dob: yup.string().required("DOB is Required"),
  user: yup.string().required("User type is Required"),

  mobileNumber: yup
    .string()
    .required("Mobile number is required")
    .test("is-valid-number", "Invalid Number", (mobileNumber) => {
      return mobileNumber ? isValidPhoneNumber(mobileNumber) : true;
    }),

  state: yup.string().required("State is required"),
  address: yup.string(),
  gender: yup.string().required("Gender is required"),
  postCode: yup
    .string()
    .required("Post Code is required")
    .matches(/^[0-9]+$/, "Must be only digits"),
  country: yup.string().required("Country is required"),
  role: yup.string().required("Role is required"),
  suburb: yup.string().required("Suburb is required"),
  joinDate: yup.string().required("Joining date is required"),
});

export const instructorSchema = yup.object().shape({
  givenName: yup.string().required("Given Name is required"),
  surname: yup.string().required("Surname is required"),
  instructorDisplayName: yup
    .string()
    .required("Instructor displayName is required"),
  instructorEmail: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  dob: yup.string().required("DOB is Required"),
  instructorMobileNumber: yup
    .string()
    .required("Mobile number is required")
    .test("is-valid-number", "Invalid Number", (mobileNumber) => {
      return mobileNumber ? isValidPhoneNumber(mobileNumber) : true;
    }),

  state: yup.string().required("State is required"),
  address: yup.string(),
  gender: yup.string().required("Gender is required"),
  postCode: yup
    .string()
    .required("Post Code is required")
    .matches(/^[0-9]+$/, "Must be only digits"),
  country: yup.string().required("Country is required"),
  suburb: yup.string().required("Suburb is required"),
  description: yup.string(),
});

export const instructorPersonalInfoSchema = yup.object().shape({
  givenName: yup.string().required("Given Name is required"),
  surname: yup.string().required("Surname is required"),
  instructorDisplayName: yup
    .string()
    .required("Instructor displayName is required"),
  dob: yup.string().required("DOB is Required"),
  gender: yup.string().required("Gender is required"),
});
export const instructorContactDetailSchema = yup.object().shape({
  instructorEmail: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  instructorMobileNumber: yup
    .string()
    .required("Mobile number is required")
    .test("is-valid-number", "Invalid Number", (mobileNumber) => {
      return mobileNumber ? isValidPhoneNumber(mobileNumber) : true;
    }),
});
export const instructorAddressDetailSchema = yup.object().shape({
  state: yup.string().required("State is required"),
  address: yup.string(),
  postCode: yup
    .string()
    .required("Post Code is required")
    .matches(/^[0-9]+$/, "Must be only digits"),
  country: yup.string().required("Country is required"),
  suburb: yup.string().required("Suburb is required"),
});
export const instructorDescriptionSchema = yup.object().shape({
  description: yup.string().required("Description is required"),
});

export const personalInformationSchema = yup.object().shape({
  givenName: yup.string().required("Given Name is required"),
  surname: yup.string().required("Surname is required"),
});

export const memberSchema = yup.object().shape({
  givenName: yup.string().required("Given Name is required"),
  surname: yup.string().required("Surname is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref("email"), null], "Email do not match")
    .required("Confirm Email is required"),
  dob: yup
    .string()
    .required("DOB is Required")
    .test(
      "DOB",
      "Sorry you cannot join your age should be more than 13 years to join",
      (value) => {
        return differenceInYears(new Date(), new Date(value)) >= 13;
      }
    ),
  mobileNumber: yup
    .string()
    .min(10, "Mobile minimum length should be 13")
    .required("Mobile number is required"),

  state: yup.string().required("State is required"),
  address: yup.string().required("Address is required"),
  gender: yup.string().required("Gender is required"),
  postCode: yup
    .string()
    .required("Post Code is required")
    .matches(/^[0-9]+$/, "Must be only digits"),
  country: yup.string().required("Country is required"),
  suburb: yup.string().required("Suburb is required"),
  emerName: yup.string().required("Emergency contact name is required"),
  emerPhone: yup.string().required("Emergency phone is required"),
  emerRelship: yup.string().required("Emergency relationship is required"),
});

export const mobileSchema = yup.object().shape({
  mobilePhone: yup
    .string()
    .min(13, "Mobile minimum length should be 10")
    .required("Mobile number is required"),
});

export const stateSchema = yup.object().shape({
  state: yup.string().required("State is required"),
});

export const givenNameSchema = yup.object().shape({
  givenName: yup.string().required("Given Name is required"),
});

export const surnameSchema = yup.object().shape({
  surname: yup.string().required("Surname is required"),
});

export const genderSchema = yup.object().shape({
  gender: yup.string().required("Gender is required"),
});

export const roleSchema = yup.object().shape({
  role: yup.string().required("Role is required"),
});

export const dateOfBirthSchema = yup.object().shape({
  dateOfBirth: yup
    .string()
    .required("DOB is Required")
    .test(
      "DOB",
      "Sorry you cannot join your age should be more than 13 years to join",
      (value) => {
        return differenceInYears(new Date(), new Date(value)) >= 13;
      }
    ),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),
  // password: yup
  //   .string()
  //   .required("Password is required")
  //   .min(6, "Password has to be at least 6 characters"),
});

export const signupSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Your password has to be at least 6 characters"),
  confirmPassword: yup
    .string()
    .required("ConfirmPassword Required")
    .oneOf([yup.ref("password")], "Passwords do not match"),
});

export const stepOneSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old Password is Required"),
  newPassword: yup
    .string()
    .required("Password is required")
    .min(8, "Your password has to be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one uppercase letter, \n one lowercase letter, \n one number, \n and one special character"
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords do not match!")
    .required("Confirm Password is required"),
});

export const forgotPasswordSchema = yup.object().shape({
  code: yup.string().required("Code is Required"),
  new_password: yup
    .string()
    .required("Password is required")
    .min(8, "Your password has to be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one uppercase letter, \n one lowercase letter, \n one number, \n and one special character"
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords do not match!")
    .required("Confirm Password is required"),
});

export const searchSchema = yup.object().shape({
  search: yup.string().required("Search is required"),
});

export const guestEntrySchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  contactNumber: yup
    .string()
    .required("Mobile number is required")
    .test("is-valid-number", "Invalid Number", (contactNumber) => {
      return contactNumber ? isValidPhoneNumber(contactNumber) : true;
    }),
  reasonForEntry: yup.string().required("Reason for entry is required"),
});

export const bulkSmsSchema = yup.object().shape({
  filter: yup.string().required("Send Bulk Sms to is required"),
  message: yup.string().required("Message is required"),
});

// export const requiredPhoneSchema = yup.object().shape({
//   search: yup
//     .string()
//     .required("Mobile number is required")
//     .test("is-valid-number", "Invalid Number", (contactNumber) => {
//       return contactNumber ? isValidPhoneNumber(contactNumber) : true;
//     }),
// });
export const requiredPhoneSchema = yup.object().shape({
  search: yup.string().required("Member email is required"),
});
export const suspensionSchema = yup.object().shape({
  suspendFrom: yup.string().required("Start Date is Required"),
  suspendTo: yup.string().required("End Date is Required"),
  suspensionReason: yup.string().required("Suspension Reason is Required"),
});

export const cardSchema = yup.object().shape({
  cardNumber: yup
    .string()
    .required("Card Number is Required")
    .matches(/^[0-9]+$/, "Must be only digits"),
});

export const contactDetailsSchema = yup.object().shape({
  givenName: yup.string().required("First name is required"),
  surname: yup.string().required("Last name is required"),
  mobileNumber: yup
    .string()
    .required("Mobile number is required")
    .test("is-valid-number", "Invalid Number", (mobileNumber) => {
      return mobileNumber ? isValidPhoneNumber(mobileNumber) : true;
    }),
  dob: yup.string().required("DOB is Required"),

  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const membershipFormSchema = yup.object().shape({
  paymentFrequency: yup.string().required("Payment frequency is required"),
  membershipType: yup.string().required("Membership type  is required"),
  membershipPlan: yup.string().required("Membership Plan  is required"),
  membershipCost: yup.string().required("Membership Cost  is required"),
  membershipStartDate: yup
    .string()
    .required("Membership start date  is required"),
  firstDirectDebit: yup.string().required("First direct debit is required"),
  costPerFornight: yup.string().required("Cost per fornight  is required"),
  totalDueToday: yup.string().required("Total due today  is required"),
});

export const healthFormSchema = yup.object().shape({
  disability: yup.string().required("Disability is required"),
  emergencyContactName: yup
    .string()
    .required("Emergency contact number is required"),
  emergencyContactNumber: yup
    .string()
    .required("emergency contact number is required"),
  relationToYou: yup.string().required("Relation to you is required"),
});
export const addVoucherSchema = yup.object().shape({
  voucherType: yup.string(),
  code: yup
    .string()
    .required("Voucher code is required")
    .min(8, "Must be exactly 8 digits")
    .max(8, "Must be exactly 8 digits"),
  startDate: yup.string().required("Start date is required"),
  createdBy: yup.string().required("Created by is required"),
  membershipId: yup.string().required("Membership is required"),
  cost: yup.string().when("voucherType", (voucherType, schema) => {
    return voucherType === "COMPLIMENTARY"
      ? schema
      : voucherType === "FIXED"
      ? schema.required("PIF/DD (fornightly) is required")
      : voucherType === "PERCENT"
      ? schema.required("Discount is required")
      : schema.required("Cost is required");
  }),
  activation: yup.string().required("Activation Fee is required"),
  pass: yup.string().required("Access Fee is required"),
  joiningFee: yup.string().required("Joining Fee is required"),
  memberType: yup.string().required("Member type is required"),
  quantity: yup.string().when("voucherType", (voucherType, schema) => {
    return voucherType === "VISIT_PASS"
      ? schema
      : schema.required("Quantity is required");
  }),
  visit: yup.string().when("voucherType", (voucherType, schema) => {
    return voucherType === "VISIT_PASS"
      ? schema.required("Visit is required")
      : schema;
  }),
  duration: yup.string().when("voucherType", (voucherType, schema) => {
    return voucherType === "COMPLIMENTARY"
      ? schema.required("Duration is required")
      : schema;
  }),
});
export const editVoucherSchema = yup.object().shape({
  voucherType: yup.string(),
  used: yup.string(),
  startDate: yup.string().required("Start date is required"),
  quantity: yup
    .string()
    .when(["voucherType", "used"], (voucherType, used, schema) => {
      return voucherType !== "VISIT_PASS"
        ? yup
            .number()
            .min(Number(used) + 1, "Quantity must be larger than used")
            .required("Quantity is required")
        : schema;
    }),
  visit: yup.string().when("voucherType", {
    is: (voucherType) => voucherType === "VISIT_PASS", //just an e.g. you can return a function
    then: yup.string().required("Visit is required"),
    otherwise: yup.string().nullable(),
  }),
  cost: yup.string().when("voucherType", (voucherType, schema) => {
    return voucherType === "COMPLIMENTARY"
      ? yup.string().nullable()
      : voucherType === "FIXED"
      ? schema.required("PIF/DD (fornightly) is required")
      : voucherType === "PERCENT"
      ? schema.required("Discount is required")
      : schema.required("Cost is required");
  }),
  activation: yup.string().required("Activation is required"),
  pass: yup.string().required("Pass is required"),
});

export const sendVoucherSchema = yup.object().shape({
  contact: yup.lazy((value) =>
    !/^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\d]*$/g.test(value)
      ? yup
          .string()
          .email("Invalid email address")
          .required("Email or Mobile number is required") // typeError is necessary here, otherwise we get a bad-looking yup error
      : yup
          .string()
          .required("Email or Mobile number is required")
          .test("is-valid-number", "Invalid Mobile number", (mobileNumber) => {
            return mobileNumber ? isValidPhoneNumber(mobileNumber) : true;
          })
  ),
});

export const maintenanceDatesSchema = yup.object().shape({
  startDateTime: yup.date().required("This is required"),
  endDateTime: yup
    .date()
    .required("This is required")
    .when("startDateTime", (startDate, schema) => {
      return startDate
        ? schema.min(startDate, "Must be after or equal to start date")
        : schema;
    }),
});
