import "./staff.scss";
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RiMailSendLine } from "react-icons/ri";
import { format } from "date-fns";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { SEARCH_MEMBER } from "../../gqloperations/queries";
import { useMemberContext } from "../../contexts/MemberContext";
import {
  Modal,
  StatusModal,
  Toggle,
  Table,
  Header,
  Button,
} from "../../components";
import {
  UPDATE_STAFF_ACCESS,
  UPDATE_EMAIL_OR_SEND_INVITE,
} from "../../gqloperations/mutations";
import actions from "../../assets/images/icons/action_menu.png";
import ChangeEmailModal from "../member/ChangeEmailModal";
import { useHandleError } from "../../hooks/useHandleError";

const queryItemLimit = 100;
const Contractor = () => {
  const { permissions, loggedUser } = useAuthContext();
  const { createLog } = useMemberContext();
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [sorting, setSorting] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [tableData, setTableData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [componentName, setComponentName] = useState("");
  const [showActions, setShowActions] = useState({});
  const [inviteClickedMap, setInviteClickedMap] = useState({});
  const handleError = useHandleError();

  const ref = useRef();

  const [curRow, setCurRow] = useState({
    id: "",
    staffName: "",
    isActive: false,
  });

  const navigate = useNavigate();
  const [updateEmailOrSendInvite, { loading: updateEmailOrSendInviteLoading }] =
    useMutation(UPDATE_EMAIL_OR_SEND_INVITE);

  const [
    searchMembers,
    {
      loading: searchStaffLoading,
      error: searchStaffError,
      data: searchMembersData,
      refetch,
    },
  ] = useLazyQuery(SEARCH_MEMBER, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    createLog("CLICKED Staff tab on the sidebar", "sidebar");
    searchMembers({
      variables: initialDataVariables,
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setTableData(data?.searchMembers?.items);
        setNextToken(data?.searchMembers?.nextToken);
      },
      onError: (error) => {
        handleError("Error: Cannot search member");
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);
  const initialDataVariables = {
    filter: {
      type: { eq: "STAFF" },
      isContractor: { eq: true },
    },
    sort: [{ field: "createdAt", direction: "desc" }],
  };
  const modalClose = () => {
    setComponentName("");
    setCurRow({ id: "", isActive: false });
  };

  const handleStatusClick = (rowObj) => {
    setComponentName("");
    let isActive =
      rowObj.isActive ||
      rowObj.isActive === null ||
      rowObj.isActive === undefined
        ? "DEACTIVATE"
        : "ACTIVATE";
    let values = {
      email: rowObj.email,
      operation: isActive,
    };
    updateStaffAccess({
      variables: values,

      onCompleted: (data) => {
        toast.success("Updated successfully");
        if (data.updateStaffAccess) {
          const modifiedData = tableData.map((member) => {
            if (rowObj.id === member.memberId) {
              const active =
                member.isActive ||
                member.isActive === null ||
                member.isActive === undefined
                  ? true
                  : false;
              return { ...member, isActive: !active };
            } else {
              return { ...member };
            }
          });
          setTableData(modifiedData);
        }
      },
      onError: (error) => {
        handleError(`${error}`);
      },
    });
  };

  // calling a mutation
  const [updateStaffAccess] = useMutation(UPDATE_STAFF_ACCESS);

  const handleComponent = (componentname, rowObj) => {
    setComponentName(componentname);
    setCurRow(rowObj);
  };

  const handleEditClick = (id) => {
    navigate(`/dashboard/contractor/editContractor/${id}`);
  };

  const getSearchMemberVariables = () => {
    if (searchValue.includes("@")) {
      return {
        filter: {
          type: { eq: "STAFF" },
          isContractor: { eq: true },
          email: { eq: `${searchValue.toUpperCase()}` },
        },
        sort: [{ field: "createdAt", direction: "desc" }],
        limit: queryItemLimit,
      };
    } else if (/\d/.test(searchValue)) {
      let value = searchValue;
      if (searchValue.startsWith("+")) {
        value = searchValue.replace("+", "");
      }
      if (searchValue.startsWith("0")) {
        value = searchValue.replace("0", "");
      }
      return {
        filter: {
          type: { eq: "STAFF" },
          isContractor: { eq: true },
          mobileNumber: { wildcard: `*${value}*` },
        },
        sort: [{ field: "createdAt", direction: "desc" }],
        limit: queryItemLimit,
      };
    } else {
      return {
        filter: {
          type: { eq: "STAFF" },
          isContractor: { eq: true },
          or: [
            {
              givenName: { wildcard: `*${searchValue.toUpperCase()}*` },
            },
            { surname: { wildcard: `*${searchValue.toUpperCase()}*` } },
          ],
        },
        sort: [{ field: "createdAt", direction: "desc" }],
        limit: queryItemLimit,
      };
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    !searchValue
      ? searchMembers({
          variables: initialDataVariables,
          onCompleted: (data) => setTableData(data?.searchMembers?.items),
          onError: (error) => {
            handleError(`${error}`);
          },
        })
      : searchMembers({
          variables: getSearchMemberVariables(),
          onCompleted: (data) => {
            setTableData(data?.searchMembers?.items);
            setNextToken(searchMembersData?.searchMembers?.nextToken);
          },
          onError: (error) => {
            handleError(`${error}`);
          },
        });
  };
  const handleNextToken = () => {
    setNextToken(searchMembersData?.searchMembers?.nextToken);
    if (searchMembersData?.searchMembers?.nextToken) {
      searchMembers({
        variables: {
          nextToken: searchMembersData?.searchMembers?.nextToken,
          ...getSearchMemberVariables(),
        },
        onCompleted: (data) => {
          setTableData([...tableData, ...data?.searchMembers?.items]);
          setNextToken(searchMembersData?.searchMembers?.nextToken);
        },
        onError: (error) => {
          handleError(`${error}`);
        },
      });
    }
  };

  const handleEmailClick = (memberId, email) => {
    setComponentName("change-email");
    setCurRow((cur) => ({ ...cur, id: memberId, email: email }));
  };

  const handleInviteClick = (memberId, type) => {
    if (type === "sendInvite" && !inviteClickedMap[memberId]) {
      setInviteClickedMap((prevMap) => ({
        ...prevMap,
        [memberId]: true,
      }));

      updateEmailOrSendInvite({
        variables: {
          input: {
            memberId: memberId,
            operation: "SEND_INVITE",
          },
        },
        onCompleted: () => {
          toast.success("The invite has been sent via email!");
          setComponentName("");

          setInviteClickedMap((prevMap) => ({
            ...prevMap,
            [memberId]: false,
          }));
        },
        onError: (error) => {
          handleError(`${error}`);
          setComponentName("");

          setInviteClickedMap((prevMap) => ({
            ...prevMap,
            [memberId]: false,
          }));
        },
      });
    }
  };

  const onSubmit = (values, OnSubmitProps) => {
    updateEmailOrSendInvite({
      variables: {
        input: {
          memberId: curRow?.id,
          operation: "UPDATE_EMAIL",
          email: values?.email,
        },
      },
      onCompleted: () => {
        setTimeout(() => {
          toast.success("Updated email succesfully");
          refetch();
        }, 3000);
        setTimeout(() => {
          setComponentName("");
        }, 2000);
      },
      onError: (error) => {
        handleError(`${error}`);
        setComponentName("");
      },
    });
  };
  const showEditBTN = loggedUser.getMember.role.substring(1) >= 3;
  const columns = [
    {
      accessorFn: (row) => `${row.surname}, ${row.givenName}`,
      header: () => {
        return <span> SURNAME, NAME</span>;
      },

      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "surname",
    },
    {
      accessorKey: "dob",
      header: "DOB",
      cell: (info) => {
        const date = info?.getValue()
          ? format(new Date(info?.getValue()), "dd/MM/yyyy")
          : "";
        return date;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "dob",
    },

    {
      accessorKey: "email",
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
    },

    {
      accessorFn: (originalRow) => {
        return originalRow.accepted ? (
          permissions.includes("CanEditContractor") ? (
            <Toggle
              checked={
                originalRow.isActive ||
                originalRow.isActive === null ||
                originalRow.isActive === undefined
              }
              value={
                originalRow.isActive ||
                originalRow.isActive === null ||
                originalRow.isActive === undefined
              }
              onChange={() =>
                handleComponent("status-confirm", {
                  id: originalRow.memberId,
                  email: originalRow.email,
                  name: `${originalRow.givenName} ${originalRow.surname}`,
                  isActive: originalRow.isActive,
                })
              }
            />
          ) : (
            <Toggle
              checked={
                originalRow.isActive ||
                originalRow.isActive === null ||
                originalRow.isActive === undefined
              }
              value={
                originalRow.isActive ||
                originalRow.isActive === null ||
                originalRow.isActive === undefined
              }
            />
          )
        ) : (
          ""
        );
      },
      accessorKey: "isActive",
      header: "status",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "status",
    },

    {
      id: "row-actions",
      header: "ACTIONS",
      cell: (props) => {
        const id = props?.row?.original?.memberId;
        const staffRole = props?.row?.original?.role;
        const viewEditBTN =
          loggedUser.getMember.role.substring(1) > staffRole.substring(1) ||
          loggedUser.getMember.role === "L5"
            ? "EDIT"
            : "VIEW";
        const areActionsVisible = showActions.hasOwnProperty(id);
        return (
          <>
            <div className="action-container flex-row item-centered">
              {!props.row.original.accepted && (
                <div className="email-btn-container">
                  <span className="tooltip">
                    <MdOutlineAlternateEmail
                      onClick={() => {
                        handleEmailClick(id, props?.row?.original?.email);
                      }}
                    />
                    <span className="tooltip-text fs-10">Update Email</span>
                  </span>

                  <div className="beat-or-send">
                    <span className="tooltip">
                      {inviteClickedMap[props.row.original?.memberId] &&
                      updateEmailOrSendInviteLoading ? (
                        <BeatLoader color="white" size={4} />
                      ) : (
                        <RiMailSendLine
                          className={
                            inviteClickedMap[props.row.original?.memberId] &&
                            updateEmailOrSendInviteLoading
                              ? "disabled"
                              : ""
                          }
                          onClick={() => {
                            if (
                              !inviteClickedMap[props.row.original?.memberId]
                            ) {
                              handleInviteClick(
                                props.row.original?.memberId,
                                "sendInvite"
                              );
                            }
                          }}
                        />
                      )}
                      <span className="tooltip-text fs-10">Send Invite</span>
                    </span>
                  </div>
                </div>
              )}
              <img
                className="dropdown-btn"
                src={actions}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowActions({
                    [id]: true,
                  });
                }}
                alt=""
              />
              {areActionsVisible && (
                <span className="action-options-menu " ref={ref}>
                  <ul className="membership-options">
                    {permissions.includes("CanEditContractor") &&
                      showEditBTN && (
                        <li
                          onClick={() => {
                            handleEditClick(id);
                          }}
                        >
                          <Button
                            name={viewEditBTN}
                            btntype="submit"
                            btnname="submit"
                            className="btn btn-transparent btn-small"
                          />
                        </li>
                      )}
                  </ul>
                </span>
              )}
            </div>
          </>
        );
      },
    },
  ];
  if (searchStaffLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (searchStaffError) {
    return (
      <div>
        <div>Error: Problem getting staff</div>
      </div>
    );
  }
  return (
    <div className="staff">
      <Header pageTitle="Contractor" />
      <div className="staff-container">
        {componentName && (
          <Modal>
            {componentName === "status-confirm" && (
              <StatusModal
                curRow={curRow}
                className={curRow.className}
                modalHeaderTxt={`${
                  curRow.isActive ||
                  curRow.isActive ||
                  curRow.isActive === null ||
                  curRow.isActive === undefined
                    ? "DEACTIVATE"
                    : "ACTIVATE"
                }`}
                modalParaText={`Are you sure you want to ${
                  curRow.isActive ||
                  curRow.isActive === null ||
                  curRow.isActive === undefined
                    ? "DEACTIVATE"
                    : "ACTIVATE"
                } ${curRow.name}?`}
                handleStatusClick={handleStatusClick}
                modalClose={modalClose}
              />
            )}
            {componentName === "change-email" && (
              <div className="send-sms-container pd-32">
                <ChangeEmailModal
                  onClick={() => setComponentName("")}
                  onSubmit={onSubmit}
                  email={curRow?.email}
                />
              </div>
            )}
          </Modal>
        )}
        <div className=" actions-row">
          <div></div>
          <div className="right-elements mt-32 flex-wrap">
            <div className="search-container">
              <form onSubmit={handleSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value.trim())}
                      placeholder="first name, surname, mobile or email"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {permissions.includes("CanAddContractor") &&
              loggedUser.getMember.role.substring(1) >= 4 && (
                <Link to="/dashboard/contractor/addContractor">
                  <Button
                    name="add Contractor"
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-primary fs-12"
                  />
                </Link>
              )}
          </div>
        </div>

        {tableData && (
          <Table
            onRowClick={(i) => {
              if (!(permissions.includes("CanEditContractor") && showEditBTN))
                return;
              const id = i.memberId;
              handleEditClick(id);
              createLog(
                `Clicked ${i.givenName} ${i.surname}`,
                "member",
                `${i.memberId}`
              );
            }}
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            columnFilter={isFiltered}
            setColumnFilter={setIsFiltered}
            rowsPerPage={10}
            handleNextToken={handleNextToken}
            nextToken={nextToken}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            sorting={sorting}
            setSorting={setSorting}
          />
        )}
      </div>
    </div>
  );
};

export default Contractor;
