import "./sidebar.scss";
import { useState, useEffect, useRef } from "react";
import { useMemberContext } from "../../contexts/MemberContext";
import { useNavigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TbLogout } from "react-icons/tb";
import { BiHelpCircle } from "react-icons/bi";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import SidebarBrandList from "./SidebarBrandList";
import SidebarItem from "./SidebarItem";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const {
    sidebarBrandId,
    sidebarLocationName,
    getLocationsByBrandId,
    sidebarLocationData,
    getSidebarLocationId,
    setBrandID,
    createLog,
  } = useMemberContext();

  const {
    user,
    handleSignOut,
    locationAccess,
    hasAccessToHQ,
    permissions,
    loggedUser,
  } = useAuthContext();

  const [memberLocationId, setMemberLocationId] = useState(sidebarLocationName);
  const [sortedLocationItems, setSortedLocationItems] = useState([]);
  const initialLocationIdRef = useRef("");

  useEffect(() => {
    !user && navigate("/");
  }, [user, navigate]);

  const [show, setShow] = useState({
    classes: false,
    show_management: false,
    brand: false,
    user_settings: false,
  });

  const setBrand = (brandId, brandName) => {
    setShow({ ...show, brand: !show.brand });
    setBrandID(brandId, brandName);
  };

  const signout = () => {
    handleSignOut();
  };

  const handleChange = (e) => {
    setMemberLocationId(e.target.value.toLowerCase());
  };

  const handleClick = () => {
    if (memberLocationId !== "") {
      initialLocationIdRef.current = memberLocationId;
    }
    setMemberLocationId("");
  };

  const handleBlur = () => {
    if (memberLocationId === "") {
      setMemberLocationId(initialLocationIdRef.current);
    }
  };

  // useEffect(() => {
  //   getLocationsByBrandId();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sidebarBrandId]);

  useEffect(() => {
    setMemberLocationId(sidebarLocationName);
    if (sidebarLocationData.length >= 1) {
      const sortedItems = sidebarLocationData?.sort((a, b) => {
        if (a.locationName.toLowerCase() < b.locationName.toLowerCase())
          return -1;
        if (a.locationName.toLowerCase() > b.locationName.toLowerCase())
          return 1;
        return 0;
      });
      setSortedLocationItems([
        { locationId: 0, locationName: "All" },
        ...sortedItems,
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarLocationData]);

  useEffect(() => {
    const selectedLocation = sortedLocationItems.find(
      (item) =>
        item.locationName.toLowerCase() === memberLocationId.toLowerCase()
    );
    if (selectedLocation) {
      getSidebarLocationId(
        selectedLocation.locationId,
        selectedLocation.locationName
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberLocationId]);
  const canSeeMembershipModule =
    loggedUser.getMember.role === "L5" || loggedUser.getMember.role === "L4";

  return (
    <>
      {sidebarLocationData && locationAccess?.brands && (
        <div className="sidebar">
          <div className="sidebar-container">
            <div className="sidebar-list">
              <div
                className={`header ${
                  process.env.REACT_APP_ENV === "dev"
                    ? "sidebar-bg-header-color-dev"
                    : "sidebar-bg-header-color-prod"
                }`}
              >
                <SidebarBrandList
                  setShow={setShow}
                  show={show}
                  setBrand={setBrand}
                />
                <div>
                  <span className="heading-and-icon">
                    <div className="location-heading fs-14">Club Location</div>

                    <AiOutlineSearch />
                  </span>

                  <div className="location-value loc-select">
                    <input
                      type="text"
                      list="location-list"
                      placeholder="Search a Location"
                      className="select-element fs-18"
                      name="memberLocationId"
                      onChange={(e) => handleChange(e)}
                      value={memberLocationId}
                      onClick={handleClick}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />

                    <datalist id="location-list">
                      {sortedLocationItems.map((option) => {
                        return (
                          <option
                            key={option.locationId}
                            value={option.locationName.toUpperCase()}
                          />
                        );
                      })}
                    </datalist>
                  </div>
                </div>
              </div>
              <div className="sidebar-main-items">
                <ul>
                  <SidebarItem
                    link={"/dashboard"}
                    itemName="dashboard"
                    classname="sidebar-item"
                  />

                  {permissions.includes("CanViewMember") && (
                    <SidebarItem
                      link={"/dashboard/member"}
                      itemName="MEMBERS"
                      classname="sidebar-item"
                    />
                  )}

                  <div>
                    <div
                      className={`dropdown-link ${show.classes ? "open" : ""}`}
                      onClick={() =>
                        setShow({ ...show, classes: !show.classes })
                      }
                    >
                      <SidebarItem
                        itemName="classes"
                        classname="sidebar-item has-subitems"
                      />

                      <span>
                        <IoIosArrowDown />
                      </span>
                    </div>
                    {show.classes && (
                      <>
                        {permissions.includes("CanViewClassSchedule") && (
                          <SidebarItem
                            link={"classes"}
                            itemName="classes"
                            classname="sidebar-subitem"
                          />
                        )}
                        {permissions.includes("CanViewClassTemplate") && (
                          <SidebarItem
                            link={"classTemplates"}
                            itemName="class template"
                            classname="sidebar-subitem"
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <div
                      className={`dropdown-link ${
                        show.show_management ? "open" : ""
                      }`}
                      onClick={() =>
                        setShow({
                          ...show,
                          show_management: !show.show_management,
                        })
                      }
                    >
                      <SidebarItem
                        itemName="management"
                        classname="sidebar-item has-subitems"
                      />

                      <span>
                        <IoIosArrowDown />
                      </span>
                    </div>
                    {show.show_management && (
                      <>
                        <SidebarItem
                          // link={"/dashboard/guestentrylog"}
                          link={"/dashboard/accesslogs"}
                          itemName="access logs"
                          classname="sidebar-subitem has-padding"
                        />

                        {permissions.includes("CanViewAllLocations") && (
                          <>
                            <SidebarItem
                              link={"/dashboard/location"}
                              itemName="location"
                              classname="sidebar-subitem has-padding"
                            />
                          </>
                        )}
                        {permissions.includes("CanViewAllMembership") && (
                          <SidebarItem
                            link={"/dashboard/membership"}
                            itemName={
                              canSeeMembershipModule
                                ? "membership/Voucher"
                                : "Voucher"
                            }
                            classname="sidebar-subitem has-padding"
                          />
                        )}
                        {permissions.includes("CanViewStaff") && (
                          <SidebarItem
                            link={"/dashboard/staff"}
                            itemName="staff"
                            classname="sidebar-subitem has-padding"
                          />
                        )}
                        {permissions.includes("CanViewContractor") &&
                          hasAccessToHQ && (
                            <SidebarItem
                              link={"/dashboard/contractor"}
                              itemName="contractor"
                              classname="sidebar-subitem has-padding"
                            />
                          )}
                        <SidebarItem
                          link={"/dashboard/groups"}
                          itemName="groups"
                          classname="sidebar-subitem has-padding"
                        />
                        <SidebarItem
                          link={"/dashboard/bulksms"}
                          itemName="Bulk Sms"
                          classname="sidebar-subitem has-padding"
                        />
                        <SidebarItem
                          link={"/dashboard/instructors"}
                          itemName="instructors"
                          classname="sidebar-subitem has-padding"
                        />

                        {/* {permissions.includes("CanViewRoleAndPermission") && (
                          <SidebarItem
                            link={"/dashboard/rolesandpermissions"}
                            itemName="roles and permissions"
                            classname="sidebar-subitem has-padding"
                          />
                        )} */}
                        {/* <SidebarItem
                          link={"/dashboard/financial"}
                          itemName="financial"
                          classname="sidebar-subitem has-padding"
                        /> */}
                        <SidebarItem
                          link={"/dashboard/pendingapproval"}
                          itemName="Pending Approval"
                          classname="sidebar-subitem has-padding"
                        />
                        <SidebarItem
                          link={"/dashboard/medicalsuspensions"}
                          itemName="Medical Suspension"
                          classname="sidebar-subitem has-padding"
                        />
                        {/* {permissions.includes("CanViewHealthQuestion") && ( */}
                        <SidebarItem
                          link={"/dashboard/healthquestions"}
                          itemName="Health Questionnaire"
                          classname="sidebar-subitem has-padding"
                        />

                        {permissions.includes("CanViewFinancial") && (
                          <SidebarItem
                            link={"/dashboard/financial"}
                            itemName="Financial"
                            classname="sidebar-subitem has-padding"
                          />
                        )}

                        {permissions.includes("CanViewBankAccountRefund") && (
                          <SidebarItem
                            link={"/dashboard/bankaccountrefund"}
                            itemName="Bank account refund"
                            classname="sidebar-subitem has-padding"
                          />
                        )}
                        {permissions.includes("CanViewDebtCollection") && (
                          <SidebarItem
                            link={"/dashboard/debtcollection"}
                            itemName="Debt Collection"
                            classname="sidebar-subitem has-padding"
                          />
                        )}
                      </>
                    )}
                  </div>

                  <SidebarItem
                    link={"/dashboard/report"}
                    itemName="reporting"
                    classname="sidebar-item"
                  />

                  <SidebarItem
                    anchorLink={`https://kb.vivaleisure.com.au/`}
                    target="_blank"
                    itemName="it knowledgebase"
                    classname="sidebar-item"
                  />

                  <SidebarItem
                    anchorLink={`https://posa.vivaleisure.com.au/login`}
                    target="_blank"
                    itemName="pos"
                    classname="sidebar-item"
                    onClick={() => {
                      createLog("CLICKED POS tab on the sidebar", "sidebar");
                    }}
                  />

                  {/* <li className="sidebar-item fs-14"> MARKETING</li>
            <li className="sidebar-item fs-14"> PEOPLE</li> */}

                  <li className="sidebar-item fs-14 sidebar-blank"> </li>
                </ul>
                <ul className="bottom">
                  <div>
                    <div
                      className={`dropdown-link ${
                        show.user_settings ? "open" : ""
                      }`}
                      onClick={() =>
                        setShow({ ...show, user_settings: !show.user_settings })
                      }
                    >
                      <SidebarItem
                        itemName="user settings"
                        classname="sidebar-item has-subitems"
                      />

                      <span>
                        {!show.user_settings ? (
                          <FiSettings />
                        ) : (
                          <IoIosArrowUp />
                        )}
                      </span>
                    </div>
                    {show.user_settings && (
                      <>
                        <SidebarItem
                          link={"/dashboard/user_setting/all_setting"}
                          itemName="setting"
                          classname="sidebar-subitem"
                        />
                        <SidebarItem
                          link={"/dashboard/profile"}
                          itemName="profile"
                          classname="sidebar-subitem"
                        />
                        <SidebarItem
                          link={"/dashboard/changepassword"}
                          itemName="change password"
                          classname="sidebar-subitem"
                        />
                      </>
                    )}
                  </div>

                  <li className="sidebar-item fs-14" onClick={signout}>
                    <span>LOG OUT</span>
                    <span className="icon">
                      <TbLogout />
                    </span>
                  </li>
                  {/* <SidebarItem
                    hancorLink={`https://vivalabs.zendesk.com/hc/en-us`}
                    target="_blank"
                    itemName="HELP"
                    classname="sidebar-item"
                  > */}
                  {/* <span className="icon">
                      <BiHelpCircle />
                    </span>
                  </SidebarItem> */}
                  <a
                    className="flex-row"
                    href="https://vivalabs.zendesk.com/hc/en-us"
                    target="_blank"
                  >
                    <li className="sidebar-item fs-14">
                      <span>HELP</span>
                      <span className="icon">
                        <BiHelpCircle />
                      </span>
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
